import { domReady } from '@roots/sage/client';
import Alpine from 'alpinejs';

import './components/posts';
import './components/singlePost';

const main = async (err) => {
  if (err) {
    console.error(err);
  }

  window.Alpine = Alpine;
  Alpine.start();
};

domReady(main);
import.meta.webpackHot?.accept(main);
