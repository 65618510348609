import Alpine from 'alpinejs';

const BASE_URL = `https://evo.evoluma.pl/`;

const DICTIONARIES_BASE = 'index.php?module=Dictionaries&action=';

const FUNDINGS_BASE = 'index.php?module=Fundings&action=';

const USER = `PKD`;
const PASS = `8NmSzchzgSi45TjKtlyC`;

const credentials = `${USER}:${PASS}`;
const encodedCredentials = btoa(credentials);

const initFilters = [
  {
    name: 'status',
    id: 3,
  },
];

const actions = {
  status: 'GetStatuses',
  ranges: 'GetRanges',
  beneficiarys: 'GetBeneficiarys',
  area: 'GetAreas',
  organizer: 'GetOrganizers',
  // program: 'GetPrograms',
  // year: 'GetYears',
  // quarter: 'GetQuarters',
};

Alpine.data('postsList', () => ({
  posts: [],
  page: 1,
  lastPage: 10,
  isLoading: false,
  tempFilters: initFilters,
  filters: initFilters,
  opened: [],
  availableFilters: actions,
  filtersOpen: false,
  translations: {
    status: 'Status',
    beneficiarys: 'Beneficjenci',
    area: 'Obszar geograficzny',
    ranges: 'Zakres tematyczny',
    organizer: 'Instytucja zarządzająca/pośrednicząca',
    // program: 'Program',
    // year: 'Czas',
    // quarter: 'Strefy',
  },

  init() {
    this.fetchFilters();
    this.fetchPosts();
  },

  postsList() {},

  setPage(page) {
    this.page = page;
    this.fetchPosts();
  },

  toggleOption(name, id, type) {
    const preventUnmark = type === 'radio';

    const multiSelect = name === 'beneficiarys' || name === 'ranges';

    const index = this.tempFilters.findIndex((filter) => filter.name === name);

    if (index === -1) {
      this.tempFilters.push({ name, id });
    } else {
      if (multiSelect) {
        const filterIdx = this.tempFilters.findIndex(
          (filter) => filter.name === name && filter.id === id
        );

        if (filterIdx !== -1) {
          this.tempFilters.splice(filterIdx, 1);
        } else {
          this.tempFilters.push({ name, id });
        }
      } else {
        const filter = this.tempFilters[index];

        if (filter.id === id && !preventUnmark) {
          this.tempFilters.splice(index, 1);
        } else {
          this.tempFilters.splice(index, 1, { name, id });
        }
      }
    }

    this.page = 1;

    if (window.innerWidth < 767) return;

    this.filters = this.tempFilters;

    this.fetchPosts();
  },

  isChecked(name, id) {
    return this.tempFilters.find(
      (filter) => filter.name === name && filter.id === id
    );
  },

  filtersLength() {
    return this.filters.length - 1;
  },

  clearFilters() {
    this.tempFilters = [{ ...this.filters[0], id: 3 }];
    this.filters = this.tempFilters;

    this.page = 1;
    this.fetchPosts();
  },

  filterPosts() {
    this.page = 1;
  },

  fetchFilters() {
    Object.keys(actions).forEach((name) => {
      fetch(`${BASE_URL}${DICTIONARIES_BASE}${actions[name]}`, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (name === 'status' && res[0]) {
            this.tempFilters.push({ name, id: res[0].id });
            this.filters.push({ name, id: res[0].id });
          }

          this.availableFilters[name] = {
            type: ['status'].includes(name) ? 'radio' : 'checkbox',
            name: name,
            options: res,
            doubleCol: ['area', 'status', 'beneficiarys'].includes(name),
          };
        });
    });
  },

  fetchPosts() {
    const filters = this.filters.reduce((acc, filter) => {
      return acc + '&' + filter.name + '=' + filter.id;
    }, '');

    fetch(
      `${BASE_URL}${FUNDINGS_BASE}GetFundings&page=${this.page}${filters}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.posts = res.results;

        this.lastPage = res.pagination.total;
      });
  },

  hasFilters(filter) {
    return filter.name === 'organizer';
  },

  isOpen(name) {
    return this.opened.includes(name);
  },

  addToOpened(name) {
    this.opened.push(name);
  },
  removeFromOpened(name) {
    this.opened = this.opened.filter((x) => x !== name);
  },

  getOptions(filter) {
    if (!filter.options) return [];

    if (filter.name !== 'organizer') return filter.options;

    return this.isOpen(filter.name)
      ? filter.options
      : [...filter.options].splice(0, 5);
  },
}));
