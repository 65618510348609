import Alpine from 'alpinejs';

const BASE_URL = `https://evo.evoluma.pl/`;

const FETCH_BASE = 'index.php?module=Fundings&action=GetFunding&id=';

const USER = `PKD`;
const PASS = `8NmSzchzgSi45TjKtlyC`;

const credentials = `${USER}:${PASS}`;
const encodedCredentials = btoa(credentials);

Alpine.data('singlePost', (id) => ({
  id,
  post: {},

  init() {
    this.fetchPost();
  },

  fetchPost() {
    this.post = result;

    fetch(`${BASE_URL}${FETCH_BASE}${this.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.post = res;
      });
  },
}));

const result = {
  id: 197,
  name: 'TESTOWE DOFINANSOWANIE NA PROJEKTY O CHARAKTERZE METALOWYM KLASTROWYM INFORMATYCZNYM W ROKU DWA TYSIĄCE DWADZIEŚCIA TRZY - DWA TYSIĄCE DWADZIEŚCIA CZTERY',
  status: 3,
  program:
    'INTERREG (Programy Europejskiej Współpracy Terytorialnej i Europejskiego Instrumentu Sąsiedztwa)',
  organizer: 2,
  beneficiary: [2],
  area: 4,
  range: 7,
  url: 'www.w1.pl',
  desc: '<p><b>https://www.parp.gov.pl/component/grants/grants/granty-na-eurogranty </b></p>\n',
  date: '2024-01-01',
};
